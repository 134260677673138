.HoverGallery {
    overflow: hidden;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    position: relative;
    cursor: pointer;

    .Img {
        background: #fff;
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.Hidden {
    display: none;
}

.Indicator {
    position: absolute;
    bottom: -8px;
    display: flex;

    .IndicatorBar {
        width: 32px;
        height: 3px;
        background: rgb(175, 188, 196);
        border-radius: 50px;

        &.Active {
            background: rgb(19, 65, 176);
        }
    }
}
